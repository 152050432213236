import React from "react"
import { useStaticQuery, graphql, HeadProps } from "gatsby"

import Hero from "../components/Hero/Hero"
import PageIntro from "../components/PageIntro"
import RenderModularBlock from "../components/RenderModularBlock"

import { processModules } from "../utils/utils_modules"
import { TextAreaModule } from "../models/modules"
import { getPrettyURL } from "../utils/utils_links"
import { SeoDataModel } from "../models/common"
import { getFirstArrayItem } from "../utils/utils_arrays"
import { cleanObj } from "../utils/utils"
import { BUILD_LANGS } from "../utils/constants"
import { PageModel } from "../models/pages"
import Seo from "../components/Seo"
import { getImageSrc } from "../utils/utils_images"
import parse from "html-react-parser"

interface Props {}

const defaultProps = {}

const NotFoundPage: React.FC<Props> = () => {
  const query = useStaticQuery(
    graphql`
      query {
        page: allContentstackPage(
          filter: {
            url: { eq: "/404" }
            publish_details: { locale: { eq: "en-au" } }
          }
        ) {
          nodes {
            url
            heading
            subheading
            hero_image {
              banners {
                banner {
                  image {
                    file {
                      ...CmsImageFields
                      gatsbyImageData(
                        width: 1920
                        layout: FULL_WIDTH
                        placeholder: NONE
                        quality: 80
                      )
                    }
                    link {
                      alt_text
                      url
                    }
                  }
                }
              }
            }
            intro
            body {
              modular_blocks {
                # text_area
                text_area {
                  content
                }
                # gallery
                gallery {
                  layout
                  images {
                    image {
                      file {
                        ...CmsImageFields
                        gatsbyImageData(
                          width: 1170
                          placeholder: BLURRED
                          quality: 80
                        )
                      }
                      link {
                        alt_text
                        url
                      }
                    }
                    caption
                    caption_link
                    caption_target
                  }
                }
                # banner_carousel
                banner_carousel {
                  rotate_headings
                  small_height
                  banners {
                    image {
                      file {
                        ...CmsImageFields
                        gatsbyImageData(
                          width: 1920
                          layout: FULL_WIDTH
                          placeholder: NONE
                          quality: 80
                        )
                      }
                      link {
                        alt_text
                        url
                      }
                    }
                    video
                    image_override
                    content_override
                    content
                    button_text
                    button_link
                    button_target
                    caption
                    caption_link
                    caption_target
                    small
                    darken
                    render_h1
                  }
                }
                # audio
                audio {
                  audio {
                    ...AudioFields
                  }
                }
                # instagram
                instagram {
                  url
                }
                # quote
                quote {
                  quote
                  author
                  image {
                    file {
                      ...CmsImageFields
                      gatsbyImageData(
                        width: 200
                        placeholder: BLURRED
                        quality: 80
                      )
                    }
                    link {
                      alt_text
                      url
                    }
                  }
                }
                # stackla
                stackla {
                  stackla_filter
                  layout
                  show_more
                  show_spinner
                  items_per_page
                  limit
                  heading
                  introduction
                }
                # accordion
                accordion {
                  first_open
                  accordion_items {
                    heading
                    content
                  }
                }
                # link
                linkitem {
                  link {
                    ...LinkFields
                  }
                  breadcrumb
                  heading
                  intro
                  image {
                    file {
                      ...CmsImageFields
                      gatsbyImageData(placeholder: BLURRED, quality: 95)
                    }
                    link {
                      alt_text
                      url
                    }
                  }
                }
                # link_row
                link_row {
                  links {
                    link {
                      ...LinkFields
                    }
                    content
                    image {
                      file {
                        ...CmsImageFields
                        gatsbyImageData(placeholder: BLURRED, quality: 95)
                      }
                      link {
                        alt_text
                        url
                      }
                    }
                  }
                }
                # deals_and_offers
                deals_and_offers {
                  listing {
                    ...DealsListingFields
                  }
                }
                # deals_page_listing
                deals_page_listing {
                  listing {
                    ...DealsListingFields
                  }
                }
                # search_and_book
                search_and_book {
                  display
                }
                # editorial_listing
                editorial_listing {
                  heading
                  intro_paragraph
                  layout
                  breadcrumbs
                  add_highlight
                  show_operator_links
                  include_links_at_start
                  include_immediate_children
                  manual_location
                  nearby_within_radius
                  keywords
                  include_items
                  exclude_items
                  set {
                    group {
                      set {
                        operator {
                          operator
                        }
                        nt_tags {
                          nt_tags {
                            ...TagFields
                          }
                          operator
                        }
                      }
                    }
                    operator {
                      operator
                    }
                    nt_tags {
                      nt_tags {
                        ...TagFields
                      }
                      operator
                    }
                  }
                }
                # minimap
                minimap {
                  pin_position {
                    x
                    y
                  }
                  position {
                    x
                    y
                  }
                  starting_position
                  highlight_regions
                  zoom2
                  zoom3
                  view_larger_map_link
                }
                # article_listing
                article_listing {
                  heading
                  intro_paragraph
                  read_more_url
                  read_more_text
                  enable_smart_display_for_itineraries
                  enable_smart_display_for_articles
                  include_items
                  exclude_items
                  set {
                    group {
                      set {
                        operator {
                          operator
                        }
                        nt_tags {
                          nt_tags {
                            ...TagFields
                          }
                          operator
                        }
                      }
                    }
                    operator {
                      operator
                    }
                    nt_tags {
                      nt_tags {
                        ...TagFields
                      }
                      operator
                    }
                  }
                }
                # dynamic_listing
                dynamic_listing {
                  heading
                  intro_paragraph
                  breadcrumbs
                  manual_location
                  nearby_within_radius
                  keywords
                  add_highlight
                  show_operator_links
                  show_map_list_toggle
                  show_map_by_default
                  show_region_selector
                  display_manual_selection_only
                  map
                  show_keyword_search
                  show_tabs
                  show_sub_filters
                  filters {
                    ...CustomFilterFields
                  }
                  initial_number_of_rows
                  number_of_rows_per_page
                  order_by
                  disable_add_to_trip
                  include_links_at_start
                  include_immediate_children
                  include_items
                  exclude_items
                  set {
                    group {
                      set {
                        operator {
                          operator
                        }
                        nt_tags {
                          nt_tags {
                            ...TagFields
                          }
                          operator
                        }
                      }
                    }
                    operator {
                      operator
                    }
                    nt_tags {
                      nt_tags {
                        ...TagFields
                      }
                      operator
                    }
                  }
                }
                # button_row
                button_row {
                  heading
                  intro_paragraph
                  links {
                    text
                    highlight
                    style
                    url
                    asset {
                      ...CmsAssetFields
                    }
                    item
                    new_window
                  }
                }
                # video_player
                video_player {
                  heading
                  intro_paragraph
                  random_order
                  videos {
                    ...VideoFields
                  }
                }
                # map
                map {
                  heading
                  intro_paragraph
                  tab
                  map_settings_url
                  lazy_load
                  map_id_prefix
                  markers
                  default_markers
                  map_centre {
                    latitude
                    longitude
                  }
                  drive_routes
                  flight_paths
                  default_drive_route
                  default_flight_path
                  collapse_legend
                  show_active_item
                }
                # faqs
                faqs {
                  tab
                  faqs {
                    title
                    content
                  }
                }
                #functional
                functional {
                  content_type
                }
              }
            }
            author {
              ...AuthorFields
            }
            publish_date
            blocks {
              ...AllBlocks
            }
          }
        }
      }
    `
  )

  const page = query.page.nodes[0]
  const body = processModules(page.body)

  // add intro text to first module if it is a text area
  let showIntroBlock = true
  const firstBodyBlock = body[0]
  if (firstBodyBlock && firstBodyBlock["text_area"]) {
    let module = firstBodyBlock["text_area"] as TextAreaModule
    module.pageId = page.id
    module.intro = page.intro
    showIntroBlock = false
  }

  return (
    <>
      <Hero
        heading={page.heading}
        subheading={page.subheading}
        hero_image={page.hero_image}
        id=""
      />

      {/*  Intro */}
      {showIntroBlock && page.intro && (
        <PageIntro
          pageId={page.id}
          intro={page.intro || ""}
          navCodeId={page.sideNav}
          locale={page.publish_details?.locale}
          first={showIntroBlock && page.intro != ""}
        />
      )}

      {/* Content */}
      {body.map((modularBlock, index) => {
        return (
          <RenderModularBlock
            key={`modBlock_${index}`}
            block={modularBlock}
            index={index}
            locale={page.publish_details?.locale}
            uid={page.uid}
            first={!(showIntroBlock && page.intro != "")}
            navCodeId={page.sideNav}
            atdwId={""}
          />
        )
      })}
    </>
  )
}

NotFoundPage.defaultProps = defaultProps

export default NotFoundPage

export function Head() {
  const query = useStaticQuery(
    graphql`
      query {
        page: allContentstackPage(
          filter: {
            url: { eq: "/404" }
            publish_details: { locale: { eq: "en-au" } }
          }
        ) {
          nodes {
            url
            heading
            subheading
            hero_image {
              banners {
                banner {
                  image {
                    file {
                      ...CmsImageFields
                      gatsbyImageData(
                        width: 1920
                        layout: FULL_WIDTH
                        placeholder: NONE
                        quality: 80
                      )
                    }
                    link {
                      alt_text
                      url
                    }
                  }
                }
              }
            }
            intro
            body {
              modular_blocks {
                # text_area
                text_area {
                  content
                }
                # gallery
                gallery {
                  layout
                  images {
                    image {
                      file {
                        ...CmsImageFields
                        gatsbyImageData(
                          width: 1170
                          placeholder: BLURRED
                          quality: 80
                        )
                      }
                      link {
                        alt_text
                        url
                      }
                    }
                    caption
                    caption_link
                    caption_target
                  }
                }
                # banner_carousel
                banner_carousel {
                  rotate_headings
                  small_height
                  banners {
                    image {
                      file {
                        ...CmsImageFields
                        gatsbyImageData(
                          width: 1920
                          layout: FULL_WIDTH
                          placeholder: NONE
                          quality: 80
                        )
                      }
                      link {
                        alt_text
                        url
                      }
                    }
                    video
                    image_override
                    content_override
                    content
                    button_text
                    button_link
                    button_target
                    caption
                    caption_link
                    caption_target
                    small
                    darken
                    render_h1
                  }
                }
                # audio
                audio {
                  audio {
                    ...AudioFields
                  }
                }
                # instagram
                instagram {
                  url
                }
                # quote
                quote {
                  quote
                  author
                  image {
                    file {
                      ...CmsImageFields
                      gatsbyImageData(
                        width: 200
                        placeholder: BLURRED
                        quality: 80
                      )
                    }
                    link {
                      alt_text
                      url
                    }
                  }
                }
                # stackla
                stackla {
                  stackla_filter
                  layout
                  show_more
                  show_spinner
                  items_per_page
                  limit
                  heading
                  introduction
                }
                # accordion
                accordion {
                  first_open
                  accordion_items {
                    heading
                    content
                  }
                }
                # link
                linkitem {
                  link {
                    ...LinkFields
                  }
                  breadcrumb
                  heading
                  intro
                  image {
                    file {
                      ...CmsImageFields
                      gatsbyImageData(placeholder: BLURRED, quality: 95)
                    }
                    link {
                      alt_text
                      url
                    }
                  }
                }
                # link_row
                link_row {
                  links {
                    link {
                      ...LinkFields
                    }
                    content
                    image {
                      file {
                        ...CmsImageFields
                        gatsbyImageData(placeholder: BLURRED, quality: 95)
                      }
                      link {
                        alt_text
                        url
                      }
                    }
                  }
                }
                # deals_and_offers
                deals_and_offers {
                  listing {
                    ...DealsListingFields
                  }
                }
                # deals_page_listing
                deals_page_listing {
                  listing {
                    ...DealsListingFields
                  }
                }
                # search_and_book
                search_and_book {
                  display
                }
                # editorial_listing
                editorial_listing {
                  heading
                  intro_paragraph
                  layout
                  breadcrumbs
                  add_highlight
                  show_operator_links
                  include_links_at_start
                  include_immediate_children
                  manual_location
                  nearby_within_radius
                  keywords
                  include_items
                  exclude_items
                  set {
                    group {
                      set {
                        operator {
                          operator
                        }
                        nt_tags {
                          nt_tags {
                            ...TagFields
                          }
                          operator
                        }
                      }
                    }
                    operator {
                      operator
                    }
                    nt_tags {
                      nt_tags {
                        ...TagFields
                      }
                      operator
                    }
                  }
                }
                # minimap
                minimap {
                  pin_position {
                    x
                    y
                  }
                  position {
                    x
                    y
                  }
                  starting_position
                  highlight_regions
                  zoom2
                  zoom3
                  view_larger_map_link
                }
                # article_listing
                article_listing {
                  heading
                  intro_paragraph
                  read_more_url
                  read_more_text
                  enable_smart_display_for_itineraries
                  enable_smart_display_for_articles
                  include_items
                  exclude_items
                  set {
                    group {
                      set {
                        operator {
                          operator
                        }
                        nt_tags {
                          nt_tags {
                            ...TagFields
                          }
                          operator
                        }
                      }
                    }
                    operator {
                      operator
                    }
                    nt_tags {
                      nt_tags {
                        ...TagFields
                      }
                      operator
                    }
                  }
                }
                # dynamic_listing
                dynamic_listing {
                  heading
                  intro_paragraph
                  breadcrumbs
                  manual_location
                  nearby_within_radius
                  keywords
                  add_highlight
                  show_operator_links
                  show_map_list_toggle
                  show_map_by_default
                  show_region_selector
                  display_manual_selection_only
                  map
                  show_keyword_search
                  show_tabs
                  show_sub_filters
                  filters {
                    ...CustomFilterFields
                  }
                  initial_number_of_rows
                  number_of_rows_per_page
                  order_by
                  disable_add_to_trip
                  include_links_at_start
                  include_immediate_children
                  include_items
                  exclude_items
                  set {
                    group {
                      set {
                        operator {
                          operator
                        }
                        nt_tags {
                          nt_tags {
                            ...TagFields
                          }
                          operator
                        }
                      }
                    }
                    operator {
                      operator
                    }
                    nt_tags {
                      nt_tags {
                        ...TagFields
                      }
                      operator
                    }
                  }
                }
                # button_row
                button_row {
                  heading
                  intro_paragraph
                  links {
                    text
                    highlight
                    style
                    url
                    asset {
                      ...CmsAssetFields
                    }
                    item
                    new_window
                  }
                }
                # video_player
                video_player {
                  heading
                  intro_paragraph
                  random_order
                  videos {
                    ...VideoFields
                  }
                }
                # map
                map {
                  heading
                  intro_paragraph
                  tab
                  map_settings_url
                  lazy_load
                  map_id_prefix
                  markers
                  default_markers
                  map_centre {
                    latitude
                    longitude
                  }
                  drive_routes
                  flight_paths
                  default_drive_route
                  default_flight_path
                  collapse_legend
                  show_active_item
                }
                # faqs
                faqs {
                  tab
                  faqs {
                    title
                    content
                  }
                }
                #functional
                functional {
                  content_type
                }
              }
            }
            author {
              ...AuthorFields
            }
            publish_date
            blocks {
              ...AllBlocks
            }
          }
        }
      }
    `
  )

  const page = query.page.nodes[0] as PageModel
  // SEO Fields
  const seoData: SeoDataModel = {
    slug: getPrettyURL(page),
    metaTitle: page.seo?.meta_title || page.heading,
    metaTitlePipe: undefined,
    metaDescription: page.seo?.meta_description || page.intro,
    metaKeywords: page.seo?.keywords,
    shareImage:
      page.seo?.share_image || getFirstArrayItem(page.hero_image?.banners),
    shareTitle: page.seo?.social_share_title,
    shareDescription: page.seo?.social_share_description,
    locale: page.publish_details?.locale,
    hidden: page.seo?.hidden || false,
  }
  let banner =
    page.hero_image?.banners &&
    page.hero_image?.banners[0] &&
    page.hero_image?.banners[0].banner
      ? page.hero_image?.banners[0].banner
      : null
  let locale = page.publish_details?.locale || "en-au"
  let digitalData = {
    page: cleanObj({
      entityName: page.seo?.meta_title || page.heading,
      entityMessage:
        page.seo?.social_share_description ||
        page.seo?.meta_description ||
        page.intro,
      entityThumbnailUrl: banner ? banner.image?.file?.url || "" : "",
      pageName: "northernterritory.com:errors:404",
      contentLanguage: BUILD_LANGS[locale as keyof typeof BUILD_LANGS].tracking,
      contentCategory: "itinerary page",
      contentSource: "cms",
      regions:
        page.nt_tags
          ?.filter(x => x.taxonomy === "Regions")
          .map(x => x.display_name.toLowerCase().replace(/ /g, "-"))
          .join(",") || "",
      journeyStage:
        page.nt_tags
          ?.filter(x => x.taxonomy === "Journey Stage")
          .map(x => x.display_name.toLowerCase())
          .join(",") || "",
    }),
    product: {},
    search: {},
  }

  return (
    <>
      <script id="digitalData">{`var digitalData =${JSON.stringify(digitalData)};`}</script>
      <script
        src="//assets.adobedtm.com/launch-ENe48ac8b26e3d48f895a32db0b1702366.min.js"
        data-cfasync="false"
        id="launch"
      ></script>
      <script id="gtm">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-M7XGTM');`}</script>
      <Seo {...seoData} />
      {parse(page.developer_fields?.code_to_appear_before_close_head || "")}
      <link
        rel="preload"
        as="image"
        type="image/jpeg"
        href={getImageSrc(banner?.image?.file?.url, 48, 20)}
      ></link>
    </>
  )
}
